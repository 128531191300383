"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _publics = _interopRequireDefault(require("@/api-model/publics"));
var _HeaderSearch = _interopRequireDefault(require("@/components/HeaderSearch"));
var _socket = _interopRequireDefault(require("@/utils/socket"));
var _utils = require("@/utils");
var _NoticeBar = _interopRequireDefault(require("./NoticeBar"));
var _common = require("@/common.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import LangSelect from '@/components/LangSelect'
// import Screenfull from '@/components/Screenfull'
var _default = exports.default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    // LangSelect,
    // Screenfull,
    Search: _HeaderSearch.default,
    NoticeBar: _NoticeBar.default
  },
  data: function data() {
    return {
      timer: null,
      drawer: false,
      merId: '',
      merName: '',
      childMerchant: '',
      groupMerName: '',
      groupMerId: '',
      activeMerName: '',
      isMerchantGroup: '',
      sameCompany: ''
    };
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    avatar: function avatar() {
      return require("@/assets/avatar/".concat(this.$store.state.account.user.avatar));
    },
    username: function username() {
      return this.$store.state.account.user.nickName || this.$store.state.account.user.username;
    },
    device: function device() {
      return this.$store.state.setting.device;
    },
    text: function text() {
      var data = this.$store.state.account.noticeggObj;
      var message = data.reduce(function (arr, item, index) {
        arr.push("".concat(index + 1, "\u3001").concat(item.content));
        return arr;
      }, []);
      if (message.length > 0) {
        return '公告：' + message.join(';');
      }
      return '';
    }
  },
  mounted: function mounted() {
    this.merId = _localstorage.default.get('MER_ID', null);
    this.merName = _localstorage.default.get('MER_NAME', null);
    this.childMerchant = _localstorage.default.get('CHILD_MERCHANT', '');
    this.groupMerName = _localstorage.default.get('GROUP_MER_NAME', '');
    this.groupMerId = _localstorage.default.get('GROUP_MER_ID', null);
    this.activeMerName = _localstorage.default.get('MER_NAME', null);
    this.isMerchantGroup = _localstorage.default.get('IsMerGroup') === '1';
    this.sameCompany = _localstorage.default.get('MER_SAME', null);
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.commit('setting/toggleSidebar');
    },
    setting: function setting() {
      this.$store.commit('setting/openSettingBar', true);
    },
    logout: function logout() {
      this.clean();
    },
    clean: function clean() {
      _socket.default.close();
      _localstorage.default.clear();
      if (this.timer) {
        clearInterval(this.timer);
      }
      window.location.href = '/login';
    },
    deleteCache: function deleteCache() {
      this.$confirm(this.$t('tips.confirmDeleteCache'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(function () {
        _localstorage.default.remove('USER_ROUTER');
        _localstorage.default.remove('PERMISSIONS');
        window.location.reload();
      }).catch(function () {
        // do nothing
      });
    },
    handleChangeChildMerchant: function handleChangeChildMerchant() {
      this.drawer = true;
    },
    changeChildMerchant: function changeChildMerchant(id) {
      var _this = this;
      if (merId === id) return;
      var merId = _localstorage.default.get('MER_ID');
      var result = this.childMerchant.find(function (res) {
        return res.merId === id;
      });
      if (result) {
        _publics.default.inserMerLoginInfo({
          merId: id
        }).then(function (res) {
          if (res.data.code === '0000') {
            var _merId = result.merId,
              merName = result.merName,
              sameCompany = result.sameCompany;
            _localstorage.default.save('MER_ID', _merId);
            _localstorage.default.save('MER_NAME', merName);
            // db.save('MER_TYPE', merType)
            _localstorage.default.save('MER_SAME', sameCompany);
            _this.$router.replace({
              path: '/'
            });
            setTimeout(function () {
              window.location.reload();
            }, 200);
          } else {
            (0, _utils.newAlert)(_this.$tips, res.data.message);
          }
        });
      } else {
        (0, _utils.newAlert)(this.$tips, '请重新登录');
      }
    },
    handleClose: function handleClose(done) {
      done();
    }
  }
};