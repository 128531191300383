"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _index = _interopRequireDefault(require("@/store/index"));
var socket = {
  websocket: null,
  connection: function connection() {
    var id = _index.default.state.account.user.userId;
    this.websocket = new WebSocket("".concat(process.env.VUE_APP_BASE_WS, "/message/ist/").concat(id));
    console.log(this.websocket);
  },
  onopen: function onopen() {
    this.websocket.onopen = function (event) {
      console.log('链接socket');
    };
  },
  getMessage: function getMessage() {
    this.websocket.onmessage = function (event) {
      var message = JSON.parse(event.data);
      if (message.textMessage) {
        (0, _elementUi.Notification)({
          title: '系统提示',
          message: message.textMessage,
          duration: 5000
        });
      }
    };
  },
  onclose: function onclose() {
    this.websocket.onclose = function (event) {
      // connection()
      console.log('断开socket');
    };
  },
  close: function close() {
    this.websocket.close();
  }
};
var _default = exports.default = socket;