"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _vue = _interopRequireDefault(require("vue"));
var _store = _interopRequireDefault(require("@/store"));
var _myValidate = require("@/utils/my-validate");
// you can set in settings.js
// errorLog:'production' | ['production', 'development']
var needErrorLog = false;
function checkNeed() {
  var env = process.env.NODE_ENV;
  if ((0, _myValidate.isString)(needErrorLog)) {
    return env === needErrorLog;
  }
  if ((0, _myValidate.isArray)(needErrorLog)) {
    return needErrorLog.includes(env);
  }
  return false;
}
if (checkNeed()) {
  _vue.default.config.errorHandler = function (err, vm, info, a) {
    // Don't ask me why I use Vue.nextTick, it just a hack.
    // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
    _vue.default.nextTick(function () {
      _store.default.dispatch('errorLog/addErrorLog', {
        err: err,
        vm: vm,
        info: info,
        url: window.location.href
      });
      console.error(err, info);
    });
  };
}