"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _index = require("./../index");
// 公告方法

var api = {
  queryMerInfo: "".concat(_index.API_merchants, "/merchantInfo/queryMerInfo"),
  // 商户信息查询
  queryAccountInfo: "".concat(_index.API_merchants, "/accountInfo/queryAccountInfo"),
  // 查询商户渠道账户余额
  queryAccountLevyInfo: "".concat(_index.API_merchants, "/accountInfo/queryAccountLevyInfo"),
  // 6-20修改 查询商户渠道账户余额
  queryLevyBodyInfo: "".concat(_index.API_merchants, "/levyBodyInfo/queryLevyBodyInfo"),
  // 查询商户代征主体 --- 结算查询中弃用
  queryChannelInfo: "".concat(_index.API_merchants, "/levyBodyInfo/queryChannelInfo"),
  // 查询打款通道 --- 结算查询中弃用
  queryMerLevyInvoiceType: "".concat(_index.API_merchants, "/levyBodyInfo/queryMerLevyInvoiceType"),
  // 根据主体查询发票类目
  checkPayPassword: "".concat(_index.API_merchants, "/tradOrderInfo/checkPayPassword"),
  // 支付密码验证
  findNoticeByUserId: "message/messageInfo/findNoticeByUserId",
  // 公告
  addMessageVO: "message/messageInfo/addMessageVO",
  // 公告
  resetPayPassword: "".concat(_index.API_merchants, "/tradOrderInfo/resetPayPassword"),
  // 公告

  queryMailInfo: "".concat(_index.API_merchants, "/invoiceInfo/queryMailInfo"),
  // 查询邮寄地址
  insertAddressInfo: "".concat(_index.API_merchants, "/postAddress/insertAddressInfo"),
  // 修改邮寄地址

  upFiles: "system/upload/files",
  // 上传商户资质/合同

  // ----------------------
  getOneWhiteList: "".concat(_index.API_merchants, "/whiteList/getOneWhiteList"),
  // 获取白名单
  findFirstNotice: "message/noticeInfo/findFirstNotice",
  // 首页公告
  updateKey: "".concat(_index.API_merchants, "/whiteList/update"),
  // 获取白名单
  inserMerLoginInfo: "".concat(_index.API_merchants, "/merLoginInfo/inserMerLoginInfo"),
  // 切换商户接口
  queryListProject: "".concat(_index.API_merchants, "/projectInfoNew/queryListProject"),
  // 商户可用项目下拉框接口
  queryMerAccountInfo: "".concat(_index.API_merchants, "/accountInfo/queryMerAccountInfo"),
  // 商户主体可用余额
  queryUserProject: "".concat(_index.API_merchants, "/userProject/queryUserProject"),
  // 商户主体可用余额
  queryLevyAccountInfo: "".concat(_index.API_merchants, "/accountInfo/queryLevyAccountInfo"),
  // 查主体、通道余额
  queryInvoiceLevyBodyInfo: "".concat(_index.API_merchants, "/levyBodyInfo/queryInvoiceLevyBodyInfo"),
  // 开票申请主体查询
  queryTradeLevyBodyInfo: "".concat(_index.API_merchants, "/levyBodyInfo/queryTradeLevyBodyInfo") // 批量打款查询主体
};

var publics = {
  queryMerInfo: function queryMerInfo(params) {
    return _request.default.postJson(api.queryMerInfo, params);
  },
  queryAccountInfo: function queryAccountInfo(params) {
    return _request.default.postJson(api.queryAccountInfo, params);
  },
  queryAccountLevyInfo: function queryAccountLevyInfo(params) {
    return _request.default.postJson(api.queryAccountLevyInfo, params);
  },
  queryLevyBodyInfo: function queryLevyBodyInfo(params) {
    return _request.default.postJson(api.queryLevyBodyInfo, params);
  },
  queryInvoiceLevyBodyInfo: function queryInvoiceLevyBodyInfo(params) {
    return _request.default.postJson(api.queryInvoiceLevyBodyInfo, params);
  },
  queryChannelInfo: function queryChannelInfo(params) {
    return _request.default.postJson(api.queryChannelInfo, params);
  },
  queryMerLevyInvoiceType: function queryMerLevyInvoiceType(params) {
    return _request.default.postJson(api.queryMerLevyInvoiceType, params);
  },
  checkPayPassword: function checkPayPassword(params) {
    return _request.default.postJson(api.checkPayPassword, params);
  },
  findNoticeByUserId: function findNoticeByUserId(params) {
    return _request.default.postJson(api.findNoticeByUserId, params);
  },
  addMessageVO: function addMessageVO(params) {
    return _request.default.postJson(api.addMessageVO, params);
  },
  resetPayPassword: function resetPayPassword(params) {
    return _request.default.postJson(api.resetPayPassword, params);
  },
  queryMailInfo: function queryMailInfo(params) {
    return _request.default.postJson(api.queryMailInfo, params);
  },
  insertAddressInfo: function insertAddressInfo(params) {
    return _request.default.postJson(api.insertAddressInfo, params);
  },
  upFiles: function upFiles(params) {
    return _request.default.postJson(api.upFiles, params);
  },
  getOneWhiteList: function getOneWhiteList(params) {
    return _request.default.postJson(api.getOneWhiteList, params);
  },
  updateKey: function updateKey(params) {
    return _request.default.postJson(api.updateKey, params);
  },
  findFirstNotice: function findFirstNotice(params) {
    return _request.default.postJson(api.findFirstNotice, params);
  },
  inserMerLoginInfo: function inserMerLoginInfo(params) {
    return _request.default.postJson(api.inserMerLoginInfo, params);
  },
  queryListProject: function queryListProject(params) {
    return _request.default.postJson(api.queryListProject, params);
  },
  queryMerAccountInfo: function queryMerAccountInfo(params) {
    return _request.default.postJson(api.queryMerAccountInfo, params);
  },
  queryUserProject: function queryUserProject(params) {
    return _request.default.postJson(api.queryUserProject, params);
  },
  queryLevyAccountInfo: function queryLevyAccountInfo(params) {
    return _request.default.postJson(api.queryLevyAccountInfo, params);
  },
  queryTradeLevyBodyInfo: function queryTradeLevyBodyInfo(params) {
    return _request.default.postJson(api.queryTradeLevyBodyInfo, params);
  }
};
var _default = exports.default = publics;