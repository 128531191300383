"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.common = void 0;
var common = exports.common = {
  groupMerName: '',
  groupMerId: '',
  merId: '',
  merName: '',
  merChantList: [],
  isMerGroup: ''
};