"use strict";

var _interopRequireWildcard = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/core-js/modules/es6.array.iterator.js");
require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/core-js/modules/es6.promise.js");
require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/core-js/modules/es6.object.assign.js");
require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("font-awesome/css/font-awesome.min.css");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./utils/error-log");
var _request = _interopRequireDefault(require("@/utils/request"));
var filters = _interopRequireWildcard(require("./filters"));
var _permissionDirect = require("./utils/permissionDirect");
var _validateDirect = require("./utils/validateDirect");
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _common = require("@/common.js");
// a modern alternative to CSS resets

// import './styles/element-variables.scss'

// font awesome
// global css

// internationalization
// icon
// error log

// global filters

var Plugins = [_permissionDirect.hasPermission, _permissionDirect.hasNoPermission, _permissionDirect.hasAnyPermission, _validateDirect.isNumber];
Plugins.map(function (plugin) {
  _vue.default.use(plugin);
});
_vue.default.use(_elementUi.default, {
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
window.addEventListener('visibilitychange', function () {
  _common.common.merId = _localstorage.default.get('MER_ID', null);
  _common.common.merName = _localstorage.default.get('MER_NAME', '');
  // if (document.hidden === false) {
  //   console.log('hidden')
  // }
  // if (document.hidden == false && common.merId != db.get('MER_ID')) {
  // }
});

_vue.default.prototype.$post = _request.default.post;
_vue.default.prototype.$postJson = _request.default.postJson;
_vue.default.prototype.$get = _request.default.get;
_vue.default.prototype.$put = _request.default.put;
_vue.default.prototype.$delete = _request.default.delete;
_vue.default.prototype.$download = _request.default.download;
_vue.default.prototype.$downFiles = _request.default.downFiles;
_vue.default.prototype.$upload = _request.default.upload;
_vue.default.prototype.$login = _request.default.login;
_vue.default.prototype.$tips = '系统提示';
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});