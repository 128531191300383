"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  computed: {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // console.log(route)
      if (meta.activeMenu) {
        console.log(meta.activeMenu);
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.setting.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.$store.state.setting.sidebar.opened;
    },
    userRoutes: function userRoutes() {
      return this.$store.state.account.routes;
    }
  },
  methods: {
    // 点击刷新页面
    // menuResetPage(key,keyPath){
    //   const url = this.$route.fullPath
    //   if(key === url){
    //     this.$router.replace({
    //       path: '/redirect' + url
    //     })
    //   }
    // }
  }
};