"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasPermission = exports.hasNoPermission = exports.hasAnyPermission = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
// 定义一些和权限有关的 Vue指令

// 必须包含列出的所有权限，元素才显示
var hasPermission = exports.hasPermission = {
  install: function install(Vue) {
    Vue.directive('hasPermission', {
      bind: function bind(el, binding, vnode) {
        var permissions = vnode.context.$store.state.account.permissions;
        var value = binding.value;
        var flag = true;
        var _iterator = (0, _createForOfIteratorHelper2.default)(value),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var v = _step.value;
            if (!permissions.includes(v)) {
              flag = false;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 当不包含列出的权限时，渲染该元素
var hasNoPermission = exports.hasNoPermission = {
  install: function install(Vue) {
    Vue.directive('hasNoPermission', {
      bind: function bind(el, binding, vnode) {
        var permissions = vnode.context.$store.state.account.permissions;
        var value = binding.value;
        var flag = true;
        var _iterator2 = (0, _createForOfIteratorHelper2.default)(value),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var v = _step2.value;
            if (permissions.includes(v)) {
              flag = false;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};

// 只要包含列出的任意一个权限，元素就会显示
var hasAnyPermission = exports.hasAnyPermission = {
  install: function install(Vue) {
    Vue.directive('hasAnyPermission', {
      bind: function bind(el, binding, vnode) {
        var permissions = vnode.context.$store.state.account.permissions;
        var value = binding.value;
        var flag = false;
        var _iterator3 = (0, _createForOfIteratorHelper2.default)(value),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var v = _step3.value;
            if (permissions.includes(v)) {
              flag = true;
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none';
          } else {
            el.parentNode.removeChild(el);
          }
        }
      }
    });
  }
};