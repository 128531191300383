"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
// deptName
var _default = exports.default = {
  namespaced: true,
  state: {
    accessToken: _localstorage.default.get('ACCESS_TOKEN'),
    refreshToken: _localstorage.default.get('REFRESH_TOKEN'),
    routeToken: _localstorage.default.get('ROUTE_TOKEN', null),
    expireTime: _localstorage.default.get('EXPIRE_TIME', 0),
    user: _localstorage.default.get('USER'),
    permissions: _localstorage.default.get('PERMISSIONS'),
    routes: _localstorage.default.get('USER_ROUTER'),
    noticegg: _localstorage.default.get('NOTICE_GG', ''),
    noticeggObj: _localstorage.default.get('NOTICE_GG_OBJ', []),
    indexDialog: _localstorage.default.get('INDEX_DIALOG', false),
    tokenStatus: _localstorage.default.get('TOKEN_STATUS', false),
    childMerchant: _localstorage.default.get('CHILD_MERCHANT', {}),
    merId: _localstorage.default.get('MER_ID', null)
  },
  mutations: {
    setAccessToken: function setAccessToken(state, val) {
      _localstorage.default.save('ACCESS_TOKEN', val);
      state.accessToken = val;
    },
    setRefreshToken: function setRefreshToken(state, val) {
      _localstorage.default.save('REFRESH_TOKEN', val);
      state.refreshToken = val;
    },
    setExpireTime: function setExpireTime(state, val) {
      _localstorage.default.save('EXPIRE_TIME', val);
      state.expireTime = val;
    },
    setUser: function setUser(state, val) {
      _localstorage.default.save('USER', val);
      state.user = val;
    },
    setPermissions: function setPermissions(state, val) {
      _localstorage.default.save('PERMISSIONS', val);
      state.permissions = val;
    },
    setRoutes: function setRoutes(state, val) {
      _localstorage.default.save('USER_ROUTER', val);
      state.routes = val;
    },
    setRouteToken: function setRouteToken(state, val) {
      _localstorage.default.save('ROUTE_TOKEN', val);
      state.routeToken = val;
    },
    setNotice: function setNotice(state, val) {
      _localstorage.default.save('NOTICE_GG', val);
      state.noticegg = val;
    },
    setNoticeObj: function setNoticeObj(state, val) {
      _localstorage.default.save('NOTICE_GG_OBJ', val);
      state.noticeggObj = val;
    },
    setIndexDialog: function setIndexDialog(state, val) {
      _localstorage.default.save('INDEX_DIALOG', val);
      state.indexDialog = val;
    },
    setTokenStatus: function setTokenStatus(state, val) {
      _localstorage.default.save('TOKEN_STATUS', val);
      state.tokenStatus = val;
    },
    setChildMerchant: function setChildMerchant(state, val) {
      _localstorage.default.save('CHILD_MERCHANT', val);
      state.childMerchant = val;
    },
    setMerId: function setMerId(state, val) {
      _localstorage.default.save('MER_ID', val);
      state.merId = val;
    }
  }
};