var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "div",
        { staticClass: "topbar" },
        [
          _vm.text
            ? _c("notice-bar", { attrs: { text: _vm.text, speed: 15 } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _vm.sameCompany === "1"
        ? _c("div", [
            _vm.merId === _vm.groupMerId && _vm.isMerchantGroup
              ? _c("div", { staticClass: "mer-name" }, [
                  _vm._v("商户名称：" + _vm._s(_vm.groupMerName)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.merId !== _vm.groupMerId
              ? _c("div", { staticClass: "mer-name" }, [
                  _vm._v("部门：" + _vm._s(_vm.activeMerName)),
                ])
              : _vm._e(),
          ])
        : _c("div", [
            _vm.merId === _vm.groupMerId && _vm.isMerchantGroup
              ? _c("div", { staticClass: "mer-name" }, [
                  _vm._v("集团公司：" + _vm._s(_vm.groupMerName)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.merId !== _vm.groupMerId && _vm.isMerchantGroup
              ? _c("div", { staticClass: "mer-name" }, [
                  _vm._v("集团子公司：" + _vm._s(_vm.activeMerName)),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isMerchantGroup
              ? _c("div", { staticClass: "mer-name" }, [
                  _vm._v("商户名称：" + _vm._s(_vm.activeMerName)),
                ])
              : _vm._e(),
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar, alt: "avatar" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(_vm._s(_vm.username)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/index" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.profile")) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.deleteCache },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.deleteCache")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isMerchantGroup
                    ? _c("el-dropdown-item", [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.handleChangeChildMerchant },
                          },
                          [_vm._v("切换子账户")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isMerchantGroup
        ? _c(
            "el-drawer",
            {
              attrs: {
                title: "切换集团子账户",
                visible: _vm.drawer,
                "append-to-body": true,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c("h3", { staticClass: "active-merchant" }, [
                _vm._v(_vm._s(_vm.merName)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "child-merchant" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select",
                      attrs: {
                        filterable: "",
                        placeholder: "请选择商户",
                        size: "mini",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeChildMerchant($event)
                        },
                      },
                      model: {
                        value: _vm.merId,
                        callback: function ($$v) {
                          _vm.merId = $$v
                        },
                        expression: "merId",
                      },
                    },
                    _vm._l(_vm.childMerchant, function (item) {
                      return _c("el-option", {
                        key: item.merId,
                        attrs: { label: item.merName, value: item.merId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "child-merchant scroll-layout" },
                [
                  _c(
                    "el-scrollbar",
                    _vm._l(_vm.childMerchant, function (item, index) {
                      return _c(
                        "a",
                        {
                          key: item.merId,
                          on: {
                            click: function ($event) {
                              return _vm.changeChildMerchant(item.merId)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(index + 1 + ". " + item.merName) +
                              "\n          "
                          ),
                          item.merType === "1"
                            ? _c("span", { staticClass: "mer-group" }, [
                                _vm._v("集团账户"),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }