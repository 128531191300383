var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          ref: "noticeBar",
          staticClass: "notice-bar",
          on: { click: _vm.handleClick },
        },
        [
          _c(
            "div",
            { ref: "textContainer", staticClass: "text-container text-swiper" },
            [
              _c(
                "div",
                {
                  ref: "textWrapper",
                  staticClass: "text-wrapper",
                  style: _vm.textWrapStyle,
                },
                _vm._l(_vm.textList, function (item, index) {
                  return _c("div", { key: index, staticClass: "text" }, [
                    _c("span", [_vm._v(_vm._s(item))]),
                  ])
                }),
                0
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }