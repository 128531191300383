"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
_vue.default.use(_vuex.default);

// https://webpack.js.org/guides/dependency-management/#requirecontext
var modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
function getInitialState() {
  return {
    modules: modules,
    getters: _getters.default
  };
}
var store = new _vuex.default.Store((0, _objectSpread2.default)((0, _objectSpread2.default)({}, getInitialState()), {}, {
  mutations: {
    clearStore: function clearStore(state, val) {
      state = Object.assign(state, getInitialState());
    }
  }
}));
var _default = exports.default = store;