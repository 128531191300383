"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNumber = void 0;
require("core-js/modules/es6.regexp.replace");
// 正整数
var isNumber = exports.isNumber = {
  install: function install(Vue) {
    Vue.directive('isNumber', {
      bind: function bind(el) {
        el.onkeypress = function (event) {
          console.log(event);
          return /[\d]/.test(String.fromCharCode(event.keyCode || event.which)) || event.which === 8;
        };
        el.oninput = function () {
          console.log(el.children[0].value);
          el.children[0].value = el.children[0].value.replace(/\D/ig, '');
        };
      }
    });
  }
};

// 正整数
// export const isNumber = {
//   install(Vue){
//     Vue.directive("isNumber", {
//       bind(el) {
//         el.onkeypress = (event) => {
//           console.log(event)
//           return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8;
//         };
//         el.oninput = () => {
//           console.log(el.children[0].value)
//           el.children[0].value = el.children[0].value.replace(/\D/ig, '');
//         };
//       },
//     });
//   }
// }