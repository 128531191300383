"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkNumber = checkNumber;
exports.checkPhone = checkPhone;
exports.isArray = isArray;
exports.isExternal = isExternal;
exports.isIntegerGreaterThanZero = isIntegerGreaterThanZero;
exports.isString = isString;
exports.validAlphabets = validAlphabets;
exports.validEmail = validEmail;
exports.validLowerCase = validLowerCase;
exports.validMobile = validMobile;
exports.validURL = validURL;
exports.validUpperCase = validUpperCase;
exports.validUsername = validUsername;
require("core-js/modules/es6.regexp.to-string");
/**

 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
function validURL(url) {
  var reg = /^(https?):\/\/(.)*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
function validMobile(mobile) {
  var reg = /^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$/;
  return reg.test(mobile);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
function isIntegerGreaterThanZero(arg) {
  return /(^[1-9]\d*$)/.test(arg);
}
function checkPhone(rule, value, callback) {
  var reg = /^1[0-9]\d{9}$/;
  if (reg.test(value) || value === '') {
    callback();
  } else {
    return callback(new Error('请填写正确的手机号'));
  }
}
function checkNumber(rule, value, callback) {
  var reg = /^[1-9]\d*$/;
  if (reg.test(value) || value === '') {
    callback();
  } else {
    return callback(new Error('请填写正确的银行卡号'));
  }
}