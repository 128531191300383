"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-merchant/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _socket = _interopRequireDefault(require("@/utils/socket"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView
  },
  mixins: [_ResizeHandler.default],
  computed: {
    sidebar: function sidebar() {
      // console.log(this.$store.state.setting.sidebar)
      return this.$store.state.setting.sidebar;
    },
    device: function device() {
      return this.$store.state.setting.device;
    },
    showSettings: function showSettings() {
      return this.$store.state.setting.settingBar.opened;
    },
    needTagsView: function needTagsView() {
      return this.$store.state.setting.multipage;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.setting.fixHeader;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  },
  mounted: function mounted() {
    var _this = this;
    window.addEventListener('onunload', function (e) {
      _this.beforeunloadHandler(e);
    });
  },
  created: function created() {
    _socket.default.connection();
    _socket.default.onopen();
    _socket.default.getMessage();
    _socket.default.onclose();
  },
  deactivated: function deactivated() {
    var _this2 = this;
    window.removeEventListener('onunload', function (e) {
      _this2.beforeunloadHandler(e);
    });
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.commit('setting/closeSidebar', {
        withoutAnimation: false
      });
    },
    beforeunloadHandler: function beforeunloadHandler(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = '您是否确认离开此页面-您输入的数据可能不会被保存';
      }
      return '您是否确认离开此页面-您输入的数据可能不会被保存';
    }
  }
};