"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'NoticeBar',
  props: {
    text: {
      type: String,
      default: ''
    },
    delay: {
      //  动画延迟时间(ms)
      type: Number,
      default: 1000
    },
    outview: {
      type: Boolean,
      default: true
    },
    textGap: {
      //  横向滚动文本分段间隔
      type: String,
      default: '14px'
    },
    duration: {
      type: Number,
      default: 0
    },
    speed: {
      // 水平滚动速度，单位px/s
      type: Number,
      default: 50
    }
  },
  data: function data() {
    return {
      show: true,
      scrollInterval: null,
      itemArr: null,
      wrapWid: 0
    };
  },
  computed: {
    style: function style() {
      return {
        height: "".concat(this.height, "px"),
        lineHeight: "".concat(this.height, "px")
      };
    },
    textList: function textList() {
      if (typeof this.text === 'string') {
        return [this.text];
      } else if (Array.isArray(this.text)) {
        return this.text;
      } else {
        console.error('property text‘s type should be string or array');
        return [''];
      }
    },
    cpuDuration: {
      get: function get() {
        if (!this.duration) {
          return this.wrapperWid / this.speed;
        } else {
          return this.duration;
        }
      },
      set: function set(val) {
        return this.cpuDuration;
      }
    },
    cpuTransition: function cpuTransition() {
      return "transform ".concat(this.cpuDuration, "s linear");
    },
    textWrapStyle: function textWrapStyle() {
      return {
        marginLeft: this.outview ? '100%' : '5px'
      };
    }
  },
  watch: {
    text: {
      handler: function handler(val) {
        var _this = this;
        this.init().then(function () {
          setTimeout(function () {
            _this.loop();
          }, _this.delay);
        });
      },
      immediate: true
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.scrollInterval);
  },
  methods: {
    init: function init() {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        _this2.$nextTick(function () {
          _this2.wrapperWid = _this2.getWrapWid();
          resolve();
        });
      });
    },
    getWrapWid: function getWrapWid() {
      var _this$$refs = this.$refs,
        textWrapper = _this$$refs.textWrapper,
        textContainer = _this$$refs.textContainer;
      var wrapperWid = 0;
      this.itemArr = textWrapper.getElementsByClassName('text');
      this.containerWid = this.getElWid(textContainer);
      for (var i = 0; i < this.itemArr.length; i++) {
        var itemW = Math.ceil(this.getElWid(this.itemArr[i]));
        wrapperWid += itemW;
      }
      return wrapperWid;
    },
    scroll: function scroll(wid, lag) {
      var _this3 = this;
      var Wrap = this.$refs.textWrapper;
      this.setTransition(Wrap);
      this.setTranslate(Wrap, wid, 0, 0, this.cpuDuration * 1000 - 100).then(function () {
        _this3.initTransiton(Wrap);
        _this3.initTransform(Wrap);
        Wrap.style.marginLeft = '100%';
      });
    },
    loop: function loop() {
      var _this4 = this;
      var wW = this.wrapperWid;
      var cW = this.containerWid;
      var wid = !this.outview ? -wW : -wW - cW;
      this.scroll(wid);
      this.scrollInterval = setInterval(function () {
        _this4.scroll(-wW - cW);
      }, this.cpuDuration * 1000);
    },
    getElWid: function getElWid(dom) {
      return dom.getBoundingClientRect && dom.getBoundingClientRect().width || window.getComputedStyle(dom, null).width.replace('px', '');
    },
    initTransiton: function initTransiton(dom) {
      dom.style.transition = 'none';
    },
    initTransform: function initTransform(dom) {
      dom.style.transform = "none";
    },
    setTransition: function setTransition(dom) {
      dom.style.transition = this.cpuTransition;
    },
    setTranslate: function setTranslate(dom, x, y, z, lag) {
      return new Promise(function (resolve, reject) {
        dom && (dom.style.transform = "translate3d(".concat(x, "px, ").concat(y, "px, ").concat(z, "px)"));
        setTimeout(function () {
          resolve(true);
        }, lag);
      });
    },
    handleClick: function handleClick() {
      this.$emit('click');
    },
    handleClose: function handleClose() {
      this.show = false;
    }
  }
};