var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c("transition", { attrs: { name: "fade-transform", mode: "out-in" } }, [
        _c(
          "div",
          [
            _vm.noCatch ? _c("router-view", { key: _vm.key }) : _vm._e(),
            _vm._v(" "),
            _c(
              "keep-alive",
              { attrs: { exclude: _vm.exclude } },
              [!_vm.noCatch ? _c("router-view", { key: _vm.key }) : _vm._e()],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }