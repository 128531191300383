"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
var _common = require("@/common.js");
//
//
//
//
//
//

var noRefresh = ['邮寄信息'];
var _default = exports.default = {
  name: 'App',
  data: function data() {
    return {
      common: _common.common
    };
  },
  watch: {
    'common.merId': {
      handler: function handler(newVal, oldVal) {
        if (oldVal) {
          if (newVal !== oldVal) {
            if (noRefresh.includes(this.$route.name)) {
              // this.$router.push('/')
              window.location.href = '/';
            } else {
              window.location.reload();
            }
          }
        }
        // console.log(newVal, oldVal)
        // console.log(Object.prototype.toString.call(oldVal))
        // if (Object.prototype.toString.call(oldVal) !== '[object Number]') {
        //   if (oldVal) {
        //     if (noRefresh.includes(this.$route.name)) return
        //     window.location.reload()
        //   }
        // }
      }
    }
  }
};