"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AppMain',
  data: function data() {
    return {
      exclude: ['Remittance', 'Dashboard', 'UserManage', 'RoleManage'],
      excludeName: ['开票申请', '发票详情', '邮寄信息']
    };
  },
  computed: {
    key: function key() {
      // return this.$route.path+new Date().getTime()
      return this.$route.path;
    },
    noCatch: function noCatch() {
      return this.excludeName.includes(this.$route.name);
    }
  }
};